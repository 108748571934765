import React, { useEffect, useState } from "react";
import { NumerosALetras } from "numero-a-letras";
import {
  Document,
  Image,
  Text,
  Page,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import Logo from "../../../../Static/Img_Pred/Logopdf.png";

const styles = StyleSheet.create({
  page: {
    padding: "20px 20px",
    rowGap: "7px",
    paddingBottom: "60px", // Espacio para el pie de página
    // color: "#5D5D5A",
    color: "#40403E",
    position: "relative",
  },
  footer: {
    position: "absolute",
    bottom: "15px", // Puedes ajustar este valor según sea necesario
    left: 0,
    right: 0,
    textAlign: "right",
    fontSize: 7,
  },
  footerpage: {
    position: "absolute",
    bottom: "15px", // Puedes ajustar este valor según sea necesario
    left: 0,
    right: 30,
    textAlign: "right",
    fontSize: 7,
  },
  table: {
    width: "100%",
    fontSize: "10px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
  },
  tableContainer: {
    width: "100%",
  },
});
const DocuPDFCotizacion = ({
  color,
  datosSeleccionados,
  datosEmpresa,
  imageSrc,
  dataContenidoPdf,
}) => {
  const formatearFecha = (fechaISO) => {
    const [year, month, day] = fechaISO.split("-");
    const fecha = new Date(year, month - 1, day);
    return fecha.toLocaleDateString("es-PE", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatearFechaVencimiento = (fechaString) => {
    const fecha = new Date(fechaString);
    fecha.setDate(fecha.getDate() + 30);

    const dia = fecha.getDate().toString().padStart(2, "0");
    const meses = [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ];
    const mes = meses[fecha.getMonth()];
    const año = fecha.getFullYear();

    return `${dia}-${mes}-${año}`;
  };

  return (
    <Document
      author="Danfer"
      title="Cotizacion"
      subject="Asunto del PDF"
      keywords="react, pdf, ejemplo"
    >
      {/* Configurar el formato del PDF en tipo TICKET */}

      <Page size={"A4"} style={styles.page}>
        {/* HEADER  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <View style={{ width: "23%" }}>
            {imageSrc && <Image src={imageSrc} />}
          </View>
          <View
            style={{
              width: "46%",
              fontSize: "9px",
              textAlign: "left",
              lineHeight: "1.5px",
            }}
          >
            <Text
              style={{
                color: "black",
                fontSize: "8px",
              }}
            >
              {datosEmpresa.razon_social_empresa || ""}
            </Text>
            <Text>
              {datosEmpresa.direccion_empresa +
                " Nro. " +
                datosEmpresa.numero_empresa +
                " / " +
                datosEmpresa.departamento +
                " - " +
                datosEmpresa.provincia +
                " - " +
                datosEmpresa.distrito}
            </Text>
          </View>
          <View
            style={{
              width: "31%",
              borderRadius: "10px",
              border: "1px solid gray",
              paddingVertical: "10px",
              textAlign: "center",
              display: "flex",
            }}
          >
            <View>
              <Text style={{ fontSize: "11px" }}>
                Ruc: {datosEmpresa.ruc_empresa}
              </Text>
            </View>
            <View style={{ paddingVertical: "5px" }}>
              <Text
                style={{
                  backgroundColor: color,
                  fontWeight: "ultrabold",
                  paddingVertical: "2px",
                  fontSize: "12px",
                  color: "white",
                }}
              >
                COTIZACION
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: "11px", fontStyle: "italic" }}>
                Nro: {datosSeleccionados.cotizacion_cliente.id_cotizacion}
              </Text>
            </View>
          </View>
        </View>
        {/* FECHA */}
        <View
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontSize: "10",
              textAlign: "right",
              alignItems: "flex-end",
            }}
          >
            {datosEmpresa.distrito},{" "}
            {formatearFecha(
              datosSeleccionados.cotizacion_cliente.fecha_creado_cotizacion ||
                ""
            )}
          </Text>
        </View>
        {/* DATOS DE CLIENTE */}
        <View style={{ width: "100%", rowGap: "5px" }} wrap={false}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              rowGap: "5px",
              columnGap: "10px",
            }}
          >
            {/* DATOS CLIENTES */}
            <View
              style={{
                width: "70%",
                paddingHorizontal: "10px",
                textAlign: "left",
                lineHeight: "1.5",
                fontSize: "8px",
                rowGap: "5px",
              }}
            >
              {/* Fila */}
              <View
                style={{
                  fontWeight: "ultrabold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  {" "}
                  <View style={{ width: "15%" }}>
                    {" "}
                    <Text
                      style={{
                        color: "black",
                      }}
                    >
                      Señores:
                    </Text>
                  </View>
                  <View style={{ width: "75%" }}>
                    {" "}
                    <Text style={{ textTransform: "uppercase" }}>
                      {
                        datosSeleccionados.cotizacion_cliente
                          .razon_social_cliente
                      }
                    </Text>
                  </View>
                </View>
              </View>
              {/* Fila */}
              <View
                style={{
                  fontWeight: "ultrabold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  {" "}
                  <View style={{ width: "15%" }}>
                    {" "}
                    <Text
                      style={{
                        color: "black",
                      }}
                    >
                      Atencion:
                    </Text>
                  </View>
                  <View style={{ width: "75%" }}>
                    {" "}
                    <Text style={{ textTransform: "uppercase" }}>
                      {dataContenidoPdf.atencion_pdf}
                    </Text>
                  </View>
                </View>
              </View>
              {/* Fila */}
              <View
                style={{
                  fontWeight: "ultrabold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={{ flex: 1, flexDirection: "row" }}>
                  {" "}
                  <View style={{ width: "15%" }}>
                    {" "}
                    <Text
                      style={{
                        color: "black",
                      }}
                    >
                      Asunto:
                    </Text>
                  </View>
                  <View style={{ width: "75%" }}>
                    {" "}
                    <Text style={{ textTransform: "uppercase" }}>
                      {dataContenidoPdf.asunto_pdf}
                      {/* SERVICIO DE TRANSPORTE DE CARGA Y PAQUETERIA */}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>{" "}
        </View>{" "}
        {/* SALUDO */}
        <View style={{ fontSize: "8px", paddingLeft: "10px" }}>
          <Text>
            {/* Por medio de la presente nuestros cordiales saludos y agredeciendo
            su amabilidad de permitirnos presentarle nuestra cotizacion */}
            {dataContenidoPdf.mensaje_pdf}
          </Text>
        </View>
        {/*  FECHAS */}
        <View
          style={{ width: "100%", rowGap: "5px", paddingLeft: "10px" }}
          wrap={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              rowGap: "5px",
              columnGap: "30px",
            }}
          >
            {/* DATOS FECHAS */}

            <View
              style={{
                width: "50%",
                textAlign: "left",
                fontSize: "8px",
                rowGap: "5px",
              }}
            >
              {/* Fila */}
              <View
                style={{
                  fontWeight: "ultrabold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      borderRight: "1px solid gray",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text
                      style={{
                        color: "black",
                      }}
                    >
                      Vendedor:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingHorizontal: "2px",
                      alignContent: "center",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text style={{}}>
                      {
                        datosSeleccionados.cotizacion_cliente
                          .colaborador_usuario
                      }{" "}
                    </Text>
                  </View>
                </View>
              </View>
              {/* Fila */}
              <View
                style={{
                  fontWeight: "ultrabold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      borderRight: "1px solid gray",
                      paddingHorizontal: "2px",
                      alignContent: "center",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text
                      style={{
                        color: "black",
                      }}
                    >
                      Correo:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingHorizontal: "2px",
                      alignContent: "center",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text style={{}}>
                      {datosSeleccionados.cotizacion_cliente.email_usuario}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* DATOS DE FECHAS */}
            <View
              style={{
                width: "50%",
                textAlign: "left",
                fontSize: "8px",
                rowGap: "5px",
              }}
            >
              {/* Fila */}
              <View
                style={{
                  fontWeight: "ultrabold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      borderRight: "1px solid gray",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text
                      style={{
                        color: "black",
                      }}
                    >
                      Telefono:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingHorizontal: "2px",
                      alignContent: "center",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text style={{}}>
                      {datosSeleccionados.cotizacion_cliente.telefono_usuario}
                    </Text>
                  </View>
                </View>
              </View>
              {/* Fila */}
              <View
                style={{
                  fontWeight: "ultrabold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      borderRight: "1px solid gray",
                      paddingHorizontal: "2px",
                      alignContent: "center",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text
                      style={{
                        color: "black",
                      }}
                    >
                      Fecha de Vencimiento:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "50%",
                      paddingHorizontal: "2px",
                      alignContent: "center",
                      padding: "3px",
                    }}
                  >
                    {" "}
                    <Text style={{}}>
                      {formatearFechaVencimiento(
                        datosSeleccionados.cotizacion_cliente
                          .fecha_creado_cotizacion
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>{" "}
        </View>{" "}
        {/* TABLA DETALLE DE FACTURA */}
        <View style={styles.tableContainer}>
          <View
            style={{
              ...styles.table,
              border: "1px solid gray",
              borderRadius: "5px",
              fontSize: "7px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                backgroundColor: color,
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                borderBottom: "1px solid gray",
              }}
            >
              <View
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Item</Text>
              </View>
              <View
                style={{
                  width: "30%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Descripcion</Text>
              </View>
              <View
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Cant.</Text>
              </View>

              <View
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Peso</Text>
              </View>

              <View
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Peso Vol.</Text>
              </View>
              <View
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  borderRight: "1px solid gray",
                  padding: "3px",
                }}
              >
                <Text>Costo Env</Text>
              </View>
              <View
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  borderRight: "1px solid gray",

                  color: "white",
                  padding: "3px",
                }}
              >
                <Text>Costo Ext.</Text>
              </View>
              <View
                style={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  color: "white",
                  padding: "3px",
                }}
              >
                <Text>Valor Total</Text>
              </View>
            </View>
            {datosSeleccionados.cotizacion_destinos.map((el, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottom:
                    index === datosSeleccionados.cotizacion_destinos.length - 1
                      ? "none"
                      : "1px solid gray", // Evitar borderBottom en la última fila
                  color: "#40403E",
                  display: "flex",
                  alignContent: "center",
                }}
                minPresenceAhead={100} // Establece un umbral mínimo para evitar que se recorte
                wrap={false}
              >
                <View
                  style={{
                    width: "10%",
                    whiteSpace: "nowrap",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>{index + 1}</Text>
                </View>{" "}
                <View
                  style={{
                    width: "30%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>
                    Transporte {el.tipo_envio_cotizacion_destino} de{" "}
                    {el.tarifario_cotizacion_destino} de{" "}
                    {datosEmpresa.distrito.toUpperCase()} a {el.DESTINO}
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {el.cantidad_mercancia_cotizacion_destino}
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {parseFloat(el.peso_mercancia_cotizacion_destino).toFixed(
                      2
                    )}
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    {parseFloat(
                      el.total_peso_volumen_cotizacion_destino
                    ).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRight: "1px solid gray",
                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    S/{" "}
                    {parseFloat(el.total_tarifa_cotizacion_destino).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    padding: "3px",
                    borderRight: "1px solid gray",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "right" }}>
                    S/{" "}
                    {parseFloat(el.total_adicional_cotizacion_destino).toFixed(
                      2
                    )}
                  </Text>
                </View>
                <View
                  style={{
                    width: "10%",

                    padding: "3px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ textAlign: "center" }}>
                    S/{" "}
                    {(
                      parseFloat(el.total_tarifa_cotizacion_destino) +
                      parseFloat(el.total_adicional_cotizacion_destino)
                    ).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        {/* OBERSVACIONES Y OPERACIONES */}
        <View
          style={{ width: "100%", rowGap: "5px", paddingLeft: "10px" }}
          wrap={false}
        >
          <View
            style={{
              width: "100%",
              fontSize: "8px",
              rowGap: "5px",
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* OBSERVACIONES */}

              <View style={{ width: "65%", justifyContent: "space-between" }}>
                <View
                  style={{
                    rowGap: "3px",
                    justifyContent: "flex-start",
                  }}
                >
                  <View>
                    <Text style={{ fontSize: "9px", color: "black" }}>
                      OBSERVACIONES:
                    </Text>
                  </View>
                  <View>
                    <Text>Abonar a cuentas bancarias</Text>
                  </View>
                </View>
                <View
                  style={{ fontSize: "8px", rowGap: "3px", marginTop: "5px" }}
                >
                  <View style={{ flexDirection: "row", columnGap: "3px" }}>
                    <Text>*</Text>
                    <Text>Cuenta Bancaria Soles: </Text>
                    <Text>{datosEmpresa.cuenta_bancaria_soles_1_empresa}</Text>
                  </View>
                  <View style={{ flexDirection: "row", columnGap: "3px" }}>
                    <Text>*</Text>
                    <Text>Cuente Bancaria Soles: </Text>
                    <Text>{datosEmpresa.cuenta_bancaria_soles_2_empresa}</Text>
                  </View>
                  <View style={{ flexDirection: "row", columnGap: "3px" }}>
                    <Text>*</Text>
                    <Text>Cuenta Bancaria Dolares: </Text>
                    <Text>
                      {datosEmpresa.cuenta_bancaria_dolares_3_empresa}
                    </Text>
                  </View>
                </View>
              </View>

              {/* OPERACIONES  */}
              <View style={{ width: "35%" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    columnGap: "5px",
                  }}
                >
                  <View
                    style={{
                      width: "45%",
                      rowGap: "3px",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#bbbbbb",
                        borderRadius: "5px",
                        border: "1px solid #bbbbbb",
                        color: "white",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Text>SUBTOTAL</Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: "#bbbbbb",
                        borderRadius: "5px",
                        border: "1px solid #bbbbbb",
                        color: "white",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Text>IGV</Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: color,
                        borderRadius: "5px",
                        border: `1px solid ${color}`,
                        color: "white",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Text>TOTAL</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "45%",
                      rowGap: "3px",
                    }}
                  >
                    <View
                      style={{
                        borderRadius: "5px",
                        border: "1px solid gray",

                        alignItems: "flex-end",
                        padding: "2px",
                      }}
                    >
                      <Text>
                        S/{" "}
                        {parseFloat(
                          datosSeleccionados.cotizacion_cliente
                            .sub_total_cotizacion
                        ).toFixed(2)}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderRadius: "5px",
                        border: "1px solid gray",

                        alignItems: "flex-end",
                        padding: "2px",
                      }}
                    >
                      <Text>
                        S/{" "}
                        {parseFloat(
                          datosSeleccionados.cotizacion_cliente.igv_cotizacion
                        ).toFixed(2)}
                      </Text>
                    </View>
                    <View
                      style={{
                        borderRadius: "5px",
                        border: `1px solid ${color}`,

                        alignItems: "flex-end",
                        padding: "2px",
                      }}
                    >
                      <Text>
                        S/{" "}
                        {parseFloat(
                          datosSeleccionados.cotizacion_cliente
                            .precio_total_cotizacion
                        ).toFixed(2)}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* NOTAS Y N° CUOTAS */}
        </View>
        <View style={{ rowGap: "5px", paddingLeft: "10px" }}>
          <View style={{ fontSize: "10px" }}>
            <Text>SERVICIOS INCLUIDOS</Text>
          </View>
          <View style={{ fontSize: "8px", width: "60%", rowGap: "3px" }}>
            <View style={{ flexDirection: "row", columnGap: "3px" }}>
              <Text>*</Text>
              <Text>
                {/* Servicio de entrega un solo punto segun indicacion del cliente */}
                {dataContenidoPdf.servicio_incluido_1_pdf}
              </Text>
            </View>
            <View style={{ flexDirection: "row", columnGap: "3px" }}>
              <Text>*</Text>
              <Text>
                {/* Camiones en optimas condiciones con permisos emitidos por el MTC
                de acuerdo a las normas y regulaciones en el transporte de carga */}
                {dataContenidoPdf.servicio_incluido_2_pdf}
              </Text>
            </View>
            <View style={{ flexDirection: "row", columnGap: "3px" }}>
              <Text>*</Text>
              <Text>
                {/* Cada camion cuenta con sus materiales y equipos de seguridad */}
                {dataContenidoPdf.servicio_incluido_3_pdf}
              </Text>
            </View>
            <View style={{ flexDirection: "row", columnGap: "3px" }}>
              <Text>*</Text>
              <Text>
                {/* Combustible y pagos de peages */}
                {dataContenidoPdf.servicio_incluido_4_pdf}
              </Text>
            </View>
            <View style={{ flexDirection: "row", columnGap: "3px" }}>
              <Text>*</Text>
              <Text>
                {/* Contamos con Póliza de Seguro $ 100,000.00 */}
                {dataContenidoPdf.servicio_incluido_5_pdf}
              </Text>
            </View>
          </View>
        </View>
        {/* NOTA */}
        <View
          style={{
            width: "65%",
            fontSize: "7px",
            rowGap: "5px",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "10px",
          }}
          wrap={false}
        >
          <View
            style={{
              width: "100%",
              fontSize: "7px",
              border: "1px solid gray",
              borderRadius: "5px",
              paddingHorizontal: "5px",
              paddingVertical: "3px",
              rowGap: "5px",
              marginTop: "10px",
            }}
          >
            <Text>
              SON:{" "}
              {NumerosALetras(
                parseFloat(
                  datosSeleccionados.cotizacion_cliente.precio_total_cotizacion
                )
              )
                .replace("Pesos", "Y")
                .replace("Peso", "Y")
                .replace("M.N.", "")
                .trim()
                .toUpperCase()}{" "}
              SOLES
            </Text>
          </View>
        </View>
        {/* </Paginacion> */}
        <Text
          style={styles.footerpage}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
export default DocuPDFCotizacion;
