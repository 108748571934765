import { useEffect, useState } from "react";
import {
  IconoBasura,
  IconoLapiz,
  IconoAñadirCorreo,
} from "../../Iconos/Iconos-NavBar";
import Home from "../../Layout/Home";
import Pagination from "../Administración/Usuario/Components/PaginacionAdmin";
import Select from "react-select";
import Logo from "../../Static/Img_Pred/LogoOscuro.webp";

import { Clientes } from "./Modals/Clientes";

const Mantenimiento = () => {
  const [Titulo, setTitulo] = useState("Insertar Titulo");
  const [Mensaje, setMensaje] = useState("Insertar Mensaje ");
  const [mostrarTextos, setMostrarTextos] = useState(false); // Estado para manejar la visibilidad de los textos
  const [ClienteSeleccionado, setClienteSeleccionado] = useState("");

  const [clienteData, setClienteData] = useState({
    usuarios: "",
    contacto_area: "",
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      maxHeight: "30px",
      minHeight: "37px",
      height: "10px",
      fontSize: "16px",
      borderRadius: "10px",
      backgroundColor: "transparent",
      border: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "14px",
      margin: "6px 0",
      padding: "8px 0px",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "5px",
      padding: "4px 12px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      //display: "none", Oculta el indicador
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none", // Oculta la barrita al lado del indicador
    }),
  };

  const [modalClientes, setModalClientes] = useState(false);

  const [usuarios, setUsuarios] = useState("");

  // Funcion para mostrar el Modal de Clientes
  const mostrarModalClientes = () => {
    setModalClientes(true);
  };

  const handleChange = (e) => {
    setClienteData({
      ...clienteData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "usuarios") {
      setUsuarios(e.target.value);
    }
  };

  // Función para manejar el click en ALERTAR
  // const handleAlertar = () => {
  //   const inputTitulo = document.getElementById("input-titulo").value;
  //   const inputMensaje = document.getElementById("input-mensaje").value;

  //   setTitulo(inputTitulo || "Título de ejemplo"); // Si está vacío, usa el valor por defecto
  //   setMensaje(inputMensaje || "Mensaje de ejemplo por defecto"); // Si está vacío, usa el valor por defecto

  //   setMostrarTextos(true); // Alerta ha sido activada, muestra los textos ocultos
  // };
  // Maneja cambios en el input del Título
  const handleTituloChange = (e) => {
    const value = e.target.value;
    setTitulo(value || "Título de ejemplo"); // Si está vacío, usa el valor por defecto
    setMostrarTextos(true); // Mostrar los textos ocultos cuando el usuario empieza a escribir
  };

  // Maneja cambios en el textarea del Mensaje
  const handleMensajeChange = (e) => {
    const value = e.target.value;
    setMensaje(value || "Mensaje de ejemplo por defecto"); // Si está vacío, usa el valor por defecto
    setMostrarTextos(true); // Mostrar los textos ocultos cuando el usuario empieza a escribir
  };

  const [datosEmpresa, setDatosEmpresa] = useState({});
  useEffect(() => {
    cargarImagenEmpresa();
  }, []);

  const cargarImagenEmpresa = async () => {
    try {
      const response = await fetch(
        "https://sysdemo.byma-ve.com/BackendApiRest/Configuracion/obtenerEmpresa.php"
      );
      const data = await response.json();
      setDatosEmpresa(data);
    } catch (error) {
      console.error("Error fetching empresa:", error);
    }
  };
  return (
    <Home
      children1={
        <>
          {" "}
          <Clientes
            modalClientes={modalClientes}
            setModalClientes={setModalClientes}
            ClienteSeleccionado={ClienteSeleccionado}
            setClienteSeleccionado={setClienteSeleccionado}
          />{" "}
        </>
      }
      children2={
        <>
          {" "}
          <div className="  relative  overflow-auto     ScrollTable rounded-2xl h-[75vh] ">
            <div className="grid grid-cols-[2fr,1.2fr] gap-x-4">
              <div className="PROGRAMACIO DE ALERTAS ">
                <div className="bg-[#fff] rounded-xl">
                  <div className="py-4 font-bold  text-xl rounded-t-xl bg-blue-400 text-white px-4 ">
                    <h1>Programacion de alertas </h1>
                  </div>
                  <div className="h-[0.1px] border-b mx-4 border-gray-300 shadow mb-4"></div>
                  <div className="grid grid-cols-[1fr,400px] gap-x-4  px-4 rounded-xl  text-gray-700">
                    <div className="h-auto overflow-auto   rounded-xl  text-gray-700">
                      <div className=" w-full justify-between space-y-2 ps-2">
                        <Select
                          styles={customStyles}
                          placeholder="Elegir cliente"
                          className=" h-[38px] border rounded-lg bg-white   text-start "
                        />
                        <Select
                          styles={customStyles}
                          placeholder="Elegir area"
                          className=" h-[38px] bg-white border rounded-lg  text-start "
                        />
                      </div>{" "}
                      <div className="ESCRIBIR CORREO mt-4">
                        <div className=" top-0 right-0  justify-between flex mt-2 ps-2">
                          <label htmlFor="" className="">
                            Correos:
                          </label>
                          <button
                            onClick={mostrarModalClientes}
                            className="text-2xl text-blue-400"
                          >
                            <IconoAñadirCorreo />
                          </button>{" "}
                        </div>
                        <div className="ps-2">
                          <input
                            readOnly
                            type="text"
                            id="usuarios"
                            name="usuarios"
                            value={ClienteSeleccionado}
                            onChange={handleChange}
                            className="mt-2 p-4 border rounded-md w-full focus:ring-1 focus:outline-none focus:ring-blue-300 "
                          />
                        </div>
                      </div>
                      <div className="ALERTAS mt-2 flex justify-between">
                        <div className="text-4xl ">
                          <label className="relative inline-flex items-center w-full     rounded p-3 min-w-full">
                            <input type="checkbox" className="sr-only peer " />
                            <div className="w-10 h-6 rounded-full bg-gray-300 cursor-pointer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:absolute after:top-[.875rem] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all border-gray-300 peer-checked:bg-blue-400"></div>
                            <span className="ms-4 text-base font-normal  ">
                              Enviar Todos
                            </span>
                          </label>
                        </div>
                        <button
                          // onClick={handleAlertar}
                          className="rounded-lg ButtonAzul h-10 items-center  text-white"
                        >
                          Alertar
                        </button>
                      </div>
                    </div>
                    <div className="h-auto overflow-auto px-2 pt-3 rounded-xl  text-gray-700">
                      {" "}
                      <div className="flex  ">
                        <div className=" text-base font-normal gap-x-4 flex justify-between w-full   text-gray-700  ">
                          <div className="relative z-0 w-full group">
                            <input
                              type="date"
                              name="contacto_area"
                              id="contacto_area"
                              className=" block py-[9px] px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                              Fecha de Vigencia *
                            </label>
                          </div>
                          <div className="relative z-0 w-full group">
                            <input
                              type="time"
                              name="contacto_area"
                              id="contacto_area"
                              className=" block py-[9px] px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              required
                            />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                              Hora de vigencia
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" mt-4">
                        <div className="relative z-0 w-full group">
                          <input
                            type="text"
                            name="input-titulo"
                            id="input-titulo"
                            className=" block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-[1px]  appearance-none  border-gray-400 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            required
                            onChange={handleTituloChange} // Captura el cambio en tiempo real
                          />
                          <label className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-7">
                            Escribir titulo
                          </label>
                        </div>
                      </div>
                      <div className="w-full mt-4">
                        <textarea
                          name="input-mensaje"
                          id="input-mensaje"
                          className="text-black p-2 w-full bg-gray-200 min-h-32 overflow-y-auto ScrollTableVertical max-h-32 rounded-md  focus:ring-1 outline-none"
                          onChange={handleMensajeChange} // Captura el cambio en tiempo real
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="h-[0.1px] border-b mx-4 border-gray-300 shadow my-4"></div>

                  <div className="TABLA">
                    <div className="mx-7 relative overflow-x-auto ScrollTable min-h-auto max-h-[74vh]  bg-[#fff]   rounded-t-xl ">
                      <table className="  w-[100%] table-fixed  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                        <tbody className="  block text-left text-[#535c69] whitespace-nowrap ">
                          <thead className="text-left text-md   sticky top-0 bg-white border-b border-gray-300 text-gray-700 whitespace-nowrap ">
                            <tr className=" border-b bg-white  border-gray-300 ">
                              <th scope="col" className="px-4 py-3 w-1/4 ">
                                Acciones
                              </th>
                              <th scope="col" className="px-4 py-3 w-1/4 ">
                                Tipo de alerta
                              </th>

                              <th
                                scope="col"
                                className="px-4 py-3 w-1/4 text-gray-700"
                              >
                                Titulo
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 w-1/4 text-gray-700"
                              >
                                Fecha de vigencia
                              </th>
                              <th scope="col" className="px-4 py-3 w-1/4 ">
                                <div className="flex items-center">
                                  Hora de vigencia
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                          <tr className="border-b bg-white border-gray-300 w-full">
                            <td className="px-4 py-3 w-1/4">
                              <div className="flex justify-start text-[15px] gap-x-4 -z-10">
                                <button
                                  // onClick={OpenModal}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] 00 p-[6px]  rounded-lg active:scale-90"
                                >
                                  <IconoLapiz />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Editar
                                  </div>
                                </button>
                                <button
                                  // onClick={() => GenerarAlerta()}
                                  className="group relative  bg-[rgba(194,187,187,0.27)] hover:bg-[rgba(160,153,153,0.4)] p-[6px] rounded-lg active:scale-90"
                                >
                                  <IconoBasura />
                                  <div className="group-hover:opacity-100 opacity-0 z-10 rounded-md px-2 py-1  right-0 bottom-[26px] pointer-events-none font-semibold text-xs bg-gray-100 text-gray-700 absolute transition-opacity duration-300 whitespace-nowrap">
                                    Eliminar
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 w-1/4 ">asdsad</td>
                            <td className="px-4 py-3 w-1/4">1123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                            <td className="px-4 py-3 w-1/4">123</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mx-7 rouded-b-xl">
                      <Pagination />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" PREVISUALIZACION h-auto overflow-auto    text-gray-700">
                <div className="rounded-xl bg-[#fff] h-[57%]">
                  <div className="py-4 font-bold rounded-t-xl  text-xl bg-blue-400 text-white px-4 ">
                    <h1>Previsualización de anuncio</h1>
                  </div>
                  <div className="p-4 ">
                    <div className="border  max-h-full h-[470px] shadow-md flex flex-col justify-between">
                      <div className="space-y-4 ">
                        <div className="uppercase italic  ">
                          <div className="flex  justify-center place-items-baseline  gap-x-2">
                            <h1 className="text-4xl font-bold text-[#6C6D70] ">
                              !Siempre
                            </h1>
                            <h1 className="text-3xl font-semibold  text-[#6C6D70]">
                              {" "}
                              en contacto
                            </h1>
                          </div>
                          <div className="flex justify-center place-items-baseline">
                            <h1 className="text-6xl font-bold text-blue-500">
                              CONTIGO!{" "}
                            </h1>
                          </div>
                        </div>
                        <div className="flex  justify-center  italic mx-4 text-center">
                          <p className="text-[20px] font-semibold text-[#6C6D70]">
                            {/* Retraso en los Envíos Debido a Marchas de
                            Transportistas */}
                            {Titulo}
                          </p>
                        </div>
                        <div className="flex flex-col justify-center  italic mx-4  text-center space-y-2">
                          {mostrarTextos && (
                            <p className="text-[15px] font-semibold text-[#6C6D70]">
                              Estimados clientes,
                            </p>
                          )}
                          <p className="text-[15px] font-semibold text-[#6C6D70]">
                            {/* Queremos informarles que, debido a las marchas de
                            transportistas en la región, nuestros envíos se
                            verán retrasados, ya que no tenemos acceso a las
                            rutas habituales de transporte. Estamos monitoreando
                            la situación de cerca y les mantendremos informados
                            sobre cualquier actualización. Agradecemos su
                            comprensión y paciencia en este momento. Si tienen
                            alguna consulta, no duden en contactarnos. */}
                            {Mensaje}
                          </p>
                          {mostrarTextos && (
                            <p className="text-[15px] font-semibold text-[#6C6D70]">
                              Gracias por su comprensión.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="w-full flex justify-between">
                        <p></p>
                        {datosEmpresa.logo_oscuro_empresa && (
                          <img
                            src={datosEmpresa?.logo_oscuro_empresa || Logo}
                            className="w-[150px] "
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    ></Home>
  );
};

export default Mantenimiento;
