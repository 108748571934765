import { useState, useEffect } from "react";
export const Clientes = ({
  modalClientes,
  setModalClientes,
  ClienteSeleccionado,
  setClienteSeleccionado,
}) => {
  const [selectedClients, setSelectedClients] = useState([]);
  const [seleccionarTodos, setSeleccionarTodos] = useState(false);

  const Items1 = [
    {
      id: 1,
      nombre: "Danfer@correo.com",
    },
  ];

  const Items2 = [
    {
      id: 2,
      nombre: "Angel@gmail.com",
    },
    {
      id: 3,
      nombre: "Joseasdasdasda@gmai.com",
    },
    {
      id: 4,
      nombre: "Joseasdsads@gmail.com",
    },
    {
      id: 5,
      nombre: "Joseasdsads@gmail.com",
    },
    {
      id: 6,
      nombre: "Joseasdsads@gmail.com",
    },
    {
      id: 7,
      nombre: "Joseasdsads@gmail.com",
    },
    {
      id: 8,
      nombre: "Joseasdsads@gmail.com",
    },
    {
      id: 9,
      nombre: "Joseasdsads@gmail.com",
    },
  ];

  const [clients, setClients] = useState(
    Items2.map((item) => ({ ...item, checked: false }))
  );

  const handleCheck = (e, index) => {
    const newClients = [...clients];
    newClients[index].checked = e.target.checked;
    setClients(newClients);
    const todosSeleccionados = newClients.every((client) => client.checked);
    setSeleccionarTodos(todosSeleccionados);
  };

  const handleSeleccionarTodos = (e) => {
    const isChecked = e.target.checked;
    setSeleccionarTodos(isChecked);
    setClients(clients.map((client) => ({ ...client, checked: isChecked })));
  };

  const handleGuardar = () => {
    const selectedEmails = clients
      .filter((client) => client.checked)
      .map((client) => client.nombre);
    setSelectedClients(selectedEmails);
    setClienteSeleccionado(selectedEmails.join(", "));
    setModalClientes(false);
  };

  const CerraModal = () => {
    setModalClientes(false);
  };

  return (
    <>
      <div
        className={`side-panel-container ${
          modalClientes ? "visible" : "invisible"
        } fixed pointer-events-auto left-0 top-0 right-0 h-full bg-[rgba(0,0,0,0.4)] z-[99999999] flex justify-center items-center`}
      >
        <div
          className={`side-panel-cont-container ${
            modalClientes ? "translate-y-[00%]" : "translate-y-[250%]"
          } w-[500px] block absolute transition-transform duration-500 `}
        >
          <div className="side-panel-content-container p-4 ">
            <div className="side-panel-iframe h-full   ">
              <div className="side-panel bg-white  h-full w-auto m-0 rounded-md  ">
                <div className="side-cont-titulo mb-4 text-[25px] px-5 py-2 rounded-t-md  bg-blue-400 w-full font-semibold text-white">
                  <div className="side-titulo flex w-full  ">
                    <h1 className="side-txt mr-9 w-[75%]">
                      Seleccionar Correos
                    </h1>
                  </div>
                </div>
                <div className="section-crm pb-4 px-6 ">
                  <div className="mb-2">
                    <label htmlFor="" className="font-semibold">
                      Correo Empresa:
                    </label>
                  </div>

                  <form className="grid grid-cols-[1fr] justify-between gap-5 ScrollTable overflow-y-auto max-h-72 ">
                    {Items1.map((cliente, index) => (
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                        <input
                            type="checkbox"
                            id={`cliente-${cliente.id}`}
                            checked={cliente.checked}
                            onChange={(e) => handleCheck(e, index)}
                            className="mr-2 ms-2 "
                          />
                           <label htmlFor={`cliente-${cliente.id}`} className="text-black ">
                            {cliente.nombre}
                          </label>
                        </div>
                      </div>
                    ))}
                  </form>
                  <div className="h-[0.1px] border-b my-4 border-gray-300 shadow "></div>
                  <div className="mb-4 flex items-center justify-between">
                    <div className="cont-txt text-slate-800 text-xl font-light relative w-full flex">
                      <input
                        className="  text-sm text-slate-800 h-7 rounded-lg border  items-center  bg-white  focus:bg-gray-50 outline-none px-2  w-full  "
                        type="text"
                        placeholder="Buscar Datos "
                      />
                    </div>
                    <div className=" flex items-center whitespace-nowrap">
                      <input
                        type="checkbox"
                        id="seleccionar_todos_checkbox"
                        checked={seleccionarTodos}
                        onChange={handleSeleccionarTodos}
                        className="mr-2 ms-2 "
                      />
                      <label
                        htmlFor="seleccionar_todos_checkbox"
                        className="text-black "
                      >
                        Seleccionar Todos
                      </label>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="" className="font-semibold">
                      Correos Areas:
                    </label>
                  </div>

                  <form className="grid grid-cols-[1fr] justify-between gap-[4px] ScrollTable overflow-y-auto max-h-[170px] ">
                    {clients.map((cliente, index) => (
                      <div key={cliente.id} className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id={`cliente-${cliente.id}`}
                            checked={cliente.checked}
                            onChange={(e) => handleCheck(e, index)}
                            className="mr-2 ms-2 "
                          />
                          <label htmlFor={`cliente-${cliente.id}`} className="text-black ">
                            {cliente.nombre}
                          </label>
                        </div>
                      </div>
                    ))}
                  </form>
                  <div className="BOTON flex items-center justify-end mt-6">
                    <button
                      onClick={handleGuardar}
                      type="button"
                      className="text-white mr-4 bg-gradient-to-t from-blue-400 via-blue-500 to-blue-500 hover:bg-gradient-to-br focus:ring-0 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Guardar
                    </button>
                    <button
                      onClick={CerraModal}
                      type="button"
                      className="text-white  bg-gradient-to-t from-gray-400 via-gray-500 to-gray-500 hover:bg-gradient-to-br focus:ring-0 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
