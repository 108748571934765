import React, { createContext, useState } from "react";

// Crear el contexto
export const ComercialContext = createContext();

// Proveedor del contexto
export const ComercialProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    asunto: "",
    mensaje: "",
    servicio1: "",
    servicio2: "",
    servicio3: "",
    servicio4: "",
    servicio5: "",
  });

  // Función para actualizar los datos
  const actualizarDatos = (datos) => {
    setFormData(datos);
  };

  return (
    <ComercialContext.Provider value={{ formData, actualizarDatos }}>
      {children}
    </ComercialContext.Provider>
  );
};